import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Content, Layout, Tags, SEO } from "../components"
import config from "../../config/SiteConfig"
import PostBox from "../components/PostBox"

const PostContent = styled.div`
  margin-top: 4rem;
`
/* interface Props {
 *   data: Data
 * }
 *  */
export default (props) => {
  const post = props.data.orgContent
  const prev = null
  const next = null

  const { title, date, isoDate, relativeDate, category, tags, image, description } = post.metadata
  const attrs = {
    title: title,
    date: date,
    isoDate: isoDate,
    relativeDate: relativeDate,
    content: post.html,
    tags: tags,
    category: category,
    slug: post.fields.slug,
    image: image,
    description: description,
    wordCount: post.wordCount,
    timeToRead: post.timeToRead,
  }

  return (
    <Layout>
      {post ? (
        <>
          <SEO {...attrs} />
          <PostBox key={post.fields.slug} {...attrs} />
        </>
      ) : null}
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    orgContent(id: { eq: $id }) {
      fields {
        slug
      }
      html
      metadata {
        title
        date(formatString: "ll")
        isoDate: date(formatString: "YYYY-MM-DD")
        relativeDate: date(fromNow: true)
        category
        tags
        image
        description
      }
      wordCount
      timeToRead
    }
  }
`
