import React from "react"
import styled from "styled-components"
import { Content, Tags } from "../components"
import { Link } from "gatsby"
import { Post } from "../models"

const Meta = styled.div`
  position: relative;
  color: ${(props) => props.theme.colors.bgBorder};
  top: -1rem;
`

const Headline = styled.h1`
  margin: 0;
  margin-bottom: 1rem;
  a {
    text-decoration: none;
  }
`
const PostWrapper = styled.div`
  h1 a {
    color: ${(props) => props.theme.colors.header};
  }
`

const Thumbnail = styled.img`
  width: 100%;
`

const PostBox = ({ title, relativeDate, tags, slug, content, image, timeToRead }: Post) => (
  <PostWrapper>
    <Headline>{title}</Headline>
    <Meta>
      <span>{relativeDate}</span> / about <span>{timeToRead} min read</span> / in{" "}
      <Tags tags={tags} />
    </Meta>
    {image ? <Thumbnail src={image} alt={`${title} cover image`} /> : null}
    <Content>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Content>
  </PostWrapper>
)

export default PostBox
